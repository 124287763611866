/// <reference types="./types" />
/// <reference types="./settings" />

(() => {
  const CUSTOMER_NAME = 'shopify';

  const isDebugMode = () => {
    return localStorage.getItem('terrific_debug') === 'true';
  };

  const isPicInPicEnabled = () => {
    return localStorage.getItem('pic-in-pic-status') === 'true';
  };

  const consoleProxy = (
    message: any,
    level: 'log' | 'warn' | 'info' | 'error' | 'debug' = 'log'
  ) => {
    if (level === 'error') {
      console.error(message, 'error');
      return;
    } else if (isDebugMode()) {
      console[level](message);
    }
  };

  consoleProxy('Terrific Embed SDK: Loaded Shopify Config');

  async function updateCart(lineItem: any) {
    try {
      const response = await fetch('/cart/change.js', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          line: lineItem.line,
          quantity: lineItem.quantity,
        }),
      });

      if (!response.ok) {
        const text = await response.text();
        throw new Error(text);
      }

      return response.json();
    } catch (error) {
      console.error('Error updating item:', error);
      throw error;
    }
  }

  async function addToCart(lineItem: any) {
    try {
      const response = await fetch('/cart/add.js', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(lineItem),
      });

      if (!response.ok) {
        const text = await response.text();
        throw new Error(text);
      }

      return response.json();
    } catch (error) {
      console.error('Error adding to cart:', error);
      throw error;
    }
  }

  async function handleAddProductToShopifyCart(payload: any) {
    const items = payload.cartProducts.map((product: any) => {
      const variant = product.variantsData?.[0];
      return {
        id: variant?.externalId.split('/').pop(),
        quantity: product.quantity,
        properties: {
          '_source': 'Terrific Shoppable videos',
        },
      };
    });

    await addToCart({items});
  }

  async function handleRemoveProductFromShopifyCart(payload: any) {
    const cartResponse = await fetch('/cart.js');
    const cart = (await cartResponse.json()) as {items: any[]};

    const lines = payload.cartProducts
      .map((product: any) => {
        const variantId = product.variantsData?.[0]?.externalId.split('/').pop();

        const lineIndex = cart.items.findIndex((item) => item.variant_id == variantId);

        return lineIndex !== -1 ? {line: lineIndex + 1, quantity: 0} : null;
      })
      .filter(Boolean);

    await Promise.all(lines.map((line: any) => updateCart(line)));
  }

  async function handleUpdateProductInShopifyCart(payload: any) {
    const cartResponse = await fetch('/cart.js');
    const cart = (await cartResponse.json()) as {items: any[]};

    const lines = payload.cartProducts
      .map((product: any) => {
        const variantId = product.variantsData?.[0]?.externalId.split('/').pop();

        const lineIndex = cart.items.findIndex((item) => item.variant_id == variantId);

        return lineIndex !== -1 ? {line: lineIndex + 1, quantity: product.quantity} : null;
      })
      .filter(Boolean);

    await Promise.all(lines.map((line: any) => updateCart(line)));
  }

  async function goToCheckout() {
    try {
      const response = await fetch('/cart.js');
      const cart = (await response.json()) as {items: any[]};

      if (!cart?.items?.length) {
        console.warn('Cart is empty. Redirecting to checkout aborted.');
        return;
      }

      const cartUrl = '/checkout';

      if (isPicInPicEnabled()) {
        window.open(cartUrl);
      } else {
        window.location.href = cartUrl;
      }
    } catch (error) {
      console.error('Error redirecting to checkout:', error);
    }
  }

  window.terrificLiveSettings = {
    ...window.terrificLiveSettings,
    registerTerrificWebComponent: true,
    cartSyncConfig: {
      ...window.terrificLiveSettings?.cartSyncConfig,
      cartSyncHandler: async (data: {action: string; cartProducts: TransformedCartProduct[]}) => {
        const {action, cartProducts} = data;
        if (!cartProducts.length) return;

        switch (action) {
          case 'addProductToCart':
            await handleAddProductToShopifyCart({cartProducts});
            break;
          case 'removeProductFromCart':
            await handleRemoveProductFromShopifyCart({cartProducts});
            break;
          case 'updateProductInCart':
            await handleUpdateProductInShopifyCart({cartProducts});
            break;
        }
      },
    },
    goToCheckoutConfig: {
      clearCurrentCart: false,
      goToCheckoutHandler: async () => {
        await goToCheckout();
      },
    },
    customerName: CUSTOMER_NAME,
  };
})();
